import { Component, Vue } from 'vue-property-decorator';

import { Locale } from '@/services/i18n';
import GeneralModule from '@/store/modules/general/GeneralModule';
import { LocaleData } from '@/store/modules/general/IGeneralModule';

@Component
export class LangSwitcherMixin extends Vue {
  public selectedLocale: Locale;

  public async onLocaleChange(): Promise<void> {
    await GeneralModule.changeLanguage(this.selectedLocale);
  }

  public created(): void {
    this.selectedLocale = this.currentLocale;
  }

  get langList(): Array<LocaleData> {
    return GeneralModule.localesList;
  }

  get currentLocale(): Locale {
    return GeneralModule.appLocale;
  }
}
