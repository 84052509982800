














import { Component, Mixins } from 'vue-property-decorator';
import { LangSwitcherMixin } from '@/mixins/LangSwitcherMixin';

@Component
export default class LanguageSelector extends Mixins(LangSwitcherMixin) {}
